@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap');

.responsive-cell-block {
    min-height: 75px;
}

.text-blk {
    margin: 0;
    line-height: 25px;
}

.responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-evenly;
}

.team-head-text {
    font-size: 48px;
    font-weight: 900;
    text-align: center;
}

.team-head-text {
    line-height: 50px;
    width: 100%;
    margin: 0 0 50px;
}

.container1 {
    max-width: 1380px;
    margin: 60px auto;
    padding: 0 30px;
}

.card {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0 4px 20px 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0  30px 0;
    border-radius: 15px;
}

.card-container {
    width: 280px;
    margin: 0 10px 25px;
}

.name {
    margin: 20px 0 5px;
    font-size: 18px;
    font-weight: 800;
}

.position {
    font-weight: bolder;
    color: #EC6B1E;
    margin: 0 0 10px;
}

.feature-text {
    margin: 0 0 20px;
    color: rgb(122, 122, 122);
    line-height: 30px;
}

.social-icons {
    gap:10px;
    display: flex;
    justify-content: space-between;
}

.social-icons img{
    width: 20px;
}

.team-image-wrapper {
    /*clip-path: circle(50% at 50% 50%);*/
    max-width: 100%;
    /*height: 130px;*/
}

.team-member-image {
    border-radius: 15px 15px 0 0;
    max-width: 100%;
}

@media (max-width: 500px) {
    .card-container {
        width: 100%;
        margin: 0 0 25px;
    }
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
}

.wk-desk-1 {
    width: 8.333333%;
}

.wk-desk-2 {
    width: 16.666667%;
}

.wk-desk-3 {
    width: 25%;
}

.wk-desk-4 {
    width: 33.333333%;
}

.wk-desk-5 {
    width: 41.666667%;
}

.wk-desk-6 {
    width: 50%;
}

.wk-desk-7 {
    width: 58.333333%;
}

.wk-desk-8 {
    width: 66.666667%;
}

.wk-desk-9 {
    width: 75%;
}

.wk-desk-10 {
    width: 83.333333%;
}

.wk-desk-11 {
    width: 91.666667%;
}

.wk-desk-12 {
    width: 100%;
}

@media (max-width: 1024px) {
    .wk-ipadp-1 {
        width: 8.333333%;
    }

    .wk-ipadp-2 {
        width: 16.666667%;
    }

    .wk-ipadp-3 {
        width: 25%;
    }

    .wk-ipadp-4 {
        width: 33.333333%;
    }

    .wk-ipadp-5 {
        width: 41.666667%;
    }

    .wk-ipadp-6 {
        width: 50%;
    }

    .wk-ipadp-7 {
        width: 58.333333%;
    }

    .wk-ipadp-8 {
        width: 66.666667%;
    }

    .wk-ipadp-9 {
        width: 75%;
    }

    .wk-ipadp-10 {
        width: 83.333333%;
    }

    .wk-ipadp-11 {
        width: 91.666667%;
    }

    .wk-ipadp-12 {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .wk-tab-1 {
        width: 8.333333%;
    }

    .wk-tab-2 {
        width: 16.666667%;
    }

    .wk-tab-3 {
        width: 25%;
    }

    .wk-tab-4 {
        width: 33.333333%;
    }

    .wk-tab-5 {
        width: 41.666667%;
    }

    .wk-tab-6 {
        width: 50%;
    }

    .wk-tab-7 {
        width: 58.333333%;
    }

    .wk-tab-8 {
        width: 66.666667%;
    }

    .wk-tab-9 {
        width: 75%;
    }

    .wk-tab-10 {
        width: 83.333333%;
    }

    .wk-tab-11 {
        width: 91.666667%;
    }

    .wk-tab-12 {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .wk-mobile-1 {
        width: 8.333333%;
    }

    .wk-mobile-2 {
        width: 16.666667%;
    }

    .wk-mobile-3 {
        width: 25%;
    }

    .wk-mobile-4 {
        width: 33.333333%;
    }

    .wk-mobile-5 {
        width: 41.666667%;
    }

    .wk-mobile-6 {
        width: 50%;
    }

    .wk-mobile-7 {
        width: 58.333333%;
    }

    .wk-mobile-8 {
        width: 66.666667%;
    }

    .wk-mobile-9 {
        width: 75%;
    }

    .wk-mobile-10 {
        width: 83.333333%;
    }

    .wk-mobile-11 {
        width: 91.666667%;
    }

    .wk-mobile-12 {
        width: 100%;
    }
}


.privacy-policy-container {
    max-width: 90%;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.privacy-policy-container h2{
    font-size: 18px;
    font-weight: bolder;
}
.privacy-policy-container h1{
    font-size: 32px;
    font-weight: bolder;
}

.feedback-form-container {
    background-color: #212138;
    color: white;
    padding: 2% 5%;
    border-radius: 8px;
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
}

.feedback-title {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.feedback-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.feedback-button {
    background-color: #ff6c00;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 25%;
}

.feedback-button.active {
    background: white;
    color: black;
    border: 1px solid #ff6c00;
}

.rating-stars {
    display: flex;
    justify-content: center;
    /*margin-bottom: 20px;*/
}

.star {
    font-size: 24px;
    color: orange;
}

.star.half {
    color: white;
    position: relative;
}

.star.half::before {
    color: orange;
    position: absolute;
    width: 50%;
    overflow: hidden;
}

.feedback-textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    /*margin-bottom: 20px;*/
}

.feedback-submit-button {
    background-color: #ff6c00;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 10%;
}
