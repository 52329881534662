.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    width: 35%;
    align-items: center;

}

.close {
    background: red;
    color: white;
    width: 30px;
    height: 30px;
    font-size: 20px;
    text-align: center;
    border-radius: 100%;
    float: right;
    cursor: pointer;
    position: absolute;
    right: 5%;
}
input[type="text"], input[type="email"], input[type="file"] {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.button {

    background: #EC6B1E;
    color: white;
    font-weight: bolder;
    padding: 5px 20px;
    font-size: 16px;
    /*padding: 14px 20px;*/
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: max-content;
    border-radius: 100px;
}

